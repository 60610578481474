import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  /**
   * MEMO: ExtensionProposal[]を返します
  interface VendingHistory {
    vendibleId: number
    vendibleType: string
    sellingPrice: number
    size: string
    taxCharge: string
  }
  interface CourseVendingHistory extends VendingHistory {
    vendibleType: 'Course'
  }
  interface VendibleRecipient { userId: number, ratio: number }
  interface NominationVendingHistory extends VendingHistory {
    vendibleType: 'Nomination'
    vendibleRecipientsAttributes: VendibleRecipient[]
  }
  interface ExtensionProposal{
    course: CourseVendingHistory,
    nominations: NominationVendingHistory[]
  }
  */
  getExtensionProposals({ tableId }) {
    return axiosIns.get(
      '/v2/order/extension_proposals',
      {
        params: snakecaseKeys({ tableId }),
      },
    )
  },
}
